<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <DetailCampaign :result="result" />
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card v-if="result">
          <b-tabs>
            <b-tab
              v-if="checkPermission('click history campaign')"
              active
              title="Histori Klik"
              @click="typeHistory = 'click'"
            >
              <HistoryCampaign
                :is-loading="isLoading"
                :result="resultHistory"
                :get-data="getHistory"
              />
            </b-tab>
            <b-tab
              v-if="checkPermission('show history campaign')"
              title="Histori Lihat"
              @click="typeHistory = 'show'"
            >
              <HistoryCampaign
                :is-loading="isLoading"
                :result="resultHistory"
                :get-data="getHistory"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import DetailCampaign from '@/components/Campaign/DetailCampaign.vue'
import HistoryCampaign from '@/components/Campaign/HistoryCampaign.vue'

export default {
  title() {
    return 'Detail Campaign'
  },
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    HistoryCampaign,
    DetailCampaign,
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      result: {},
      resultHistory: [],
      isLoading: true,
      typeHistory: 'click',
    }
  },
  watch: {
    typeHistory() {
      this.getHistory()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getHistory(page = 1) {
      this.isLoading = true
      this.$http.get(`/admin/ads-campaign/history/${this.typeHistory}?campaign_id=${this.id}&service_uuid=${this.result.service.uuid}`,
        {
          params: {
            page,
          },
        })
        .then(response => {
          this.resultHistory = response.data.data
          this.isLoading = false
        })
    },
    getData() {
      this.isLoading = true
      this.$http.get(`/admin/ads-campaign/${this.id}`)
        .then(response => {
          this.result = response.data.data

          if (this.result) {
            this.getHistory()
          }
          this.isLoading = false
        })
    },
  },
}
</script>
